import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Spacer, Text } from '@chakra-ui/react';

export const PrivacyPolicy = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        minHeight: 'calc(100vh - 100px)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box m={10} style={{ flexGrow: 1 }}>
        <Spacer height={'30px'}></Spacer>
        <Text textAlign={'left'} fontSize={'x-large'} fontWeight="semibold">
          {t('menu.privacyPolicy')}
        </Text>
        <Spacer height={'2em'}></Spacer>
        <Text textAlign={'left'} fontSize={'small'} whiteSpace="pre-line">
          {t('privacyPolicy.content')}
        </Text>
        <Spacer height={'6em'}></Spacer>
      </Box>
    </div>
  );
};
